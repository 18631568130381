<template>
  <div id="header-mobile" class="header">
    <nuxt-link :to="PAGE_URLS.HOME">
      <CommonBaseImg id="logo-mobile" class="logo" :src="`${PATH_BRAND}logo.webp`" alt="logo" />
    </nuxt-link>
    <div class="header-button">
      <client-only>
        <MobileHeaderLoginButton v-if="!currentUser" />
        <MobileHeaderUserLogged v-else />
      </client-only>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PAGE_URLS } from '~/config/page-url'
import { PATH_BRAND } from '~/constants/path'
import CommonBaseImg from '~/components/common/base-img'
import MobileHeaderLoginButton from '~/components/mobile/header/user-not-login.vue'
import MobileHeaderUserLogged from '~/components/mobile/header/user-logged.vue'
import { useHeader } from '~/composables/useHeader'
const { currentUser } = useHeader()
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/header/index.scss" />
